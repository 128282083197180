<template>
    <div>
        <a-form layout="inline">
            <a-form-item label="归集点名称">
                <a-input v-model="query.centerName" allowClear placeholder="请输入归集点名称"/>
            </a-form-item>

            <a-form-item label="采集点名称">
                <a-input v-model="query.pointName" allowClear placeholder="请输入采集点名称"/>
            </a-form-item>

            <a-form-item label="设备名称">
                <a-input v-model="query.deviceName" allowClear placeholder="请输入设备名称"/>
            </a-form-item>
            <a-form-item label="绑定状态">
                <a-select allowClear style="width: 220px;" @change="handleChangeBindStatus">
                    <a-select-option :value="1">
                        已绑定
                    </a-select-option>
                    <a-select-option :value="2">
                        未绑定
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item>
                <a-button type="primary" @click="customQuery">查询</a-button>
                <a href="https://zhongshi-garbage.oss-cn-shanghai.aliyuncs.com/monitor/VideoWebPlugin.exe "
                   style="margin-left: 30px;">插件下载</a>

            </a-form-item>
        </a-form>

        <a-table :columns="columns" :data-source="data" :loading="loading" :pagination="pagination" bordered
                 rowKey="equipmentCode" size="middle"
                 @change="handleTableChange">

      <span slot="isBind" slot-scope="text, record">
        <a-tag v-if="text" color="blue">已绑定</a-tag>
        <a-tag v-else>未绑定</a-tag>
      </span>

            <span slot="action" slot-scope="text, record">
        <a-space v-if="!record.isBind">
          <a-button size="small"
                    type="primary" @click="showSelectPoint(record)">绑定</a-button>
          <a-button size="small" type="primary" @click="toPreview(record)">预览</a-button>
            <!--          <a-button type="primary" @click="toPreview2(record)">预览2</a-button>-->
        </a-space>
         <a-space v-else>
           <a-popconfirm
                   cancel-text="取消"
                   ok-text="解绑"
                   title="确认解绑?"
                   @confirm="unBind(record)">
             <a-button size="small" type="danger">解绑</a-button>
           </a-popconfirm>

           <a-button size="small" type="primary" @click="toPreview(record)">预览</a-button>
           <a-button size="small" type="primary" @click="toPlayback(record)"> 回放</a-button>
         </a-space>
      </span>
        </a-table>
        <a-button type="primary" @click="fireshImport">手动开启监控导入</a-button>
        <a-button style="margin-left: 10px;" type="danger" @click="deleteAllUnbindMonitor">
            删除所有未绑定的监控设备点位
        </a-button>
        <select-point ref="selectPoint" @bind="bind"/>

    </div>
</template>

<script>
import {deleteAllUnbindMonitor, importMonitor, queryMonitorList1} from "@/api/monitor";
import SelectPoint from "./components/selectPoint";
import {bindEquipmentApi} from "@/api/equipment";

export default {
    name: "monitorList",
    components: {
        SelectPoint
    },
    data() {
        return {
            query: {
                disposalSiteName: undefined,
                sort: undefined,
                order: undefined,
            },
            pagination: {
                pageSize: 10,
                current: 1,
                total: 0
            },
            columns: [
                {
                    title: '监控设备编号',
                    dataIndex: 'equipmentCode',
                    key: 'equipmentCode',
                    scopedSlots: {customRender: 'equipmentCode'},
                    align: 'center',
                    // sorter: true,
                },
                {
                    title: '监控设备名称',
                    dataIndex: 'information.name',
                    key: 'information.name',
                    scopedSlots: {customRender: 'information.name'},
                    align: 'center',
                    // sorter: true,
                },
                {
                    title: '通道',
                    dataIndex: 'information.extend.channelTypeName',
                    key: 'information.extend.channelTypeName',
                    scopedSlots: {customRender: 'information.extend.channelTypeName'},
                    align: 'center',
                    // sorter: true,
                },
                {
                    title: '绑定',
                    dataIndex: 'isBind',
                    key: 'isBind',
                    scopedSlots: {customRender: 'isBind'},
                    align: 'center',
                    // sorter: true,
                },
                {
                    title: '操作',
                    key: 'action',
                    scopedSlots: {customRender: 'action'},
                    align: 'center'
                },
            ],
            data: [],
            loading: true,
            currentMonitor: undefined,
        }
    },
    mounted() {
        this.queryMonitorList()
    },
    methods: {
        fireshImport() {
            importMonitor().then(res => {
                if (res.code == '0') {
                    this.$message.success('操作成功')
                }
            })
        },
        deleteAllUnbindMonitor() {
            deleteAllUnbindMonitor().then(res => {
                if (res.code == '0') {
                    this.$message.success('操作成功')
                }
            })
        },
        handleChangeBindStatus(e) {
            this.query.isBind = (e == 1 ? true : (e == 2 ? false : ''))
            this.queryMonitorList()
        },
        async queryMonitorList() {
            this.loading = true

            let data = await queryMonitorList1(this.convertQuery())
            this.convertPagination(data.result)
            this.data = this.convertData(data.result.content)

            this.loading = false
        },
        handleTableChange(pagination, filters, sorter) {
            this.pagination.pageSize = pagination.pageSize
            this.pagination.current = pagination.current
            this.query.sort = sorter.field
            this.query.order = sorter.order

            this.queryMonitorList()
        },
        customQuery() {
            this.pagination.current = 1
            this.queryMonitorList()
        },
        convertQuery() {
            return {
                ...this.query,

                pageSize: this.pagination.pageSize,
                pageNo: this.pagination.current,
                sort: this.query.sort,
                order: this.query.order,
            }
        },
        convertPagination(data) {
            this.pagination.current = data.pageNo + 1
            this.pagination.total = data.totalElements
        },
        convertData(data) {
            return data
        },
        showSelectPoint(mointor) {
            this.currentMonitor = mointor
            this.$refs.selectPoint.toggleVisible()
        },
        async bind(point) {
            bindEquipmentApi({
                equipmentCode: this.currentMonitor.equipmentCode,
                pointCode: point.systemCode,
                isBind: true,
            }).then(() => {
                this.$message.success('绑定成功')
                this.queryMonitorList()
                this.$refs.selectPoint.toggleVisible()
            }).catch(() => {
                this.$message.error('绑定失败')
            })
        },
        async unBind(monitor) {
            bindEquipmentApi({
                equipmentCode: monitor.equipmentCode,
                pointCode: monitor.collectionPoint.pointCode,
                isBind: false,
            }).then(() => {
                this.$message.success('绑定成功')
                this.queryMonitorList()
            }).catch(() => {
                this.$message.error('解绑失败')
            })

        },
        toPreview(monitor) {
            this.$router.push({
                name: 'monitorPreview1'
            })
            localStorage.setItem('equipmentCode', monitor.equipmentCode)

        },
        toPreview2(monitor) {
            this.$router.push({
                name: 'monitorPreview21',
                query: {
                    equipmentCode: monitor.equipmentCode
                }
            })
        },
        toPlayback(monitor) {
            this.$router.push({
                name: 'monitorPlayback1',
                // query: {
                //   equipmentCode: monitor.equipmentCode
                // }
            })
            localStorage.setItem('equipmentCode', monitor.equipmentCode)
        }
    },
}
</script>

<style scoped>

</style>
