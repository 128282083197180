<template>
  <a-modal :visible="visible" title="选择采集点" width="60%"
           @cancel="handleCancel" @ok="handleOk">

    <a-form layout="inline">
      <a-form-item label="采集点编号">
        <a-input v-model="query.systemCode" placeholder="输入采集点编号" @change="customQuery"/>
      </a-form-item>

      <a-form-item label="采集点名称">
        <a-input v-model="query.name" placeholder="输入采集点名称" @change="customQuery"/>
      </a-form-item>

      <a-form-item label="归集点名称">
        <a-input v-model="query.residentialName" placeholder="输入归集点名称" @change="customQuery"/>
      </a-form-item>
    </a-form>

    <a-table :columns="columns" :data-source="data" :loading="loading"
             :pagination="pagination" rowKey="id"
             @change="handleTableChange">
      <span slot="hasMonitorEquipment" slot-scope="text, record">{{record.hasMonitorEquipment ? '是' : '否' }}</span>
      <span slot="action" slot-scope="text, record">

        <a-popconfirm
            cancel-text="取消"
            ok-text="绑定"
            title="确认绑定?"
            @confirm="bind(record)">
          <a-button type="primary">绑定</a-button>
        </a-popconfirm>
      </span>
    </a-table>
  </a-modal>
</template>

<script>
import {queryCollectionList} from "@/api/collection";

export default {
  name: "selectPoint",
  data() {
    return {
      visible: false,
      query: {
        residentialName: undefined,
        name: undefined,

        sort: undefined,
        order: undefined
      },
      pagination: {
        pageSize: 5,
        current: 1,
        total: 0
      },
      columns: [
        {
          title: '采集点编号',
          dataIndex: 'systemCode',
          key: 'systemCode',
          align: 'center',
        },
        {
          title: '采集点名称',
          dataIndex: 'information.name',
          key: 'information.name',
          scopedSlots: {customRender: 'information.name'},
          align: 'center',
        },
        {
          title: '小区名称',
          dataIndex: 'information.residentialName',
          key: 'information.residentialName',
          scopedSlots: {customRender: 'information.residentialName'},
          align: 'center',
        },
        {
          title: '是否已绑定监控设备',
          dataIndex: 'hasMonitorEquipment',
          key: 'hasMonitorEquipment',
          scopedSlots: {customRender: 'hasMonitorEquipment'},
          align: 'center',
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: {customRender: 'action'},
          align: 'center'
        },
      ],
      data: [],
      loading: false,
    }
  },
  mounted() {
    this.queryCollectionList()
  },
  methods: {
    toggleVisible() {
      this.visible = !this.visible
    },
    handleOk(e) {
      this.visible = false;
    },
    handleCancel(e) {
      this.visible = false;
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.pageSize = pagination.pageSize
      this.pagination.current = pagination.current
      this.query.sort = sorter.field
      this.query.order = sorter.order

      this.queryCollectionList()
    },
    customQuery() {
      this.pagination.current = 1
      this.queryCollectionList()
    },
    async queryCollectionList() {
      this.loading = true

      let data = await queryCollectionList(this.convertQuery())

      this.convertPagination(data.result)
      this.data = this.convertData(data.result.content)

      this.loading = false
    },
    convertPagination(data) {
      this.pagination.current = data.pageNo + 1
      this.pagination.total = data.totalElements
    },
    convertData(data) {
      return data
    },
    convertQuery() {
      return {
        ...this.query,

        pageSize: this.pagination.pageSize,
        pageNo: this.pagination.current,
        sort: this.query.sort,
        order: this.query.order,
      }
    },
    bind(point) {
      this.$emit('bind', point)
    }
  },
}
</script>

<style scoped>

</style>
